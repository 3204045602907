export const COMBO_SITUACION_LABORAL = {
	2: 'Cuenta Ajena',
	1: 'Cuenta Propia / Autónomo',
	3: 'Pensionista',
	4: 'Dedicación Familiar',
	5: 'Desocupado',
	6: 'Estudiante',
	7: 'Rentista',
};

export const COMBO_INGRESOS = {
	0: 'Sin ingresos',
	1: 'Menor o igual que 15000',
	2: 'Mayor de 15000 hasta 35000',
	3: 'Mayor de 35000 hasta 75000',
	4: 'Mayor que 75000',
};

export const COMBO_ORIGEN_INGRESOS = {
	1: 'Nómina/pensión/desempleo',
	2: 'Actividad profesional',
	3: 'Inversiones Financieras',
	4: 'Alquileres',
	5: 'Otros',
};

export const COMBO_CNO = {
	'0011': 'Oficiales de las fuerzas armadas',
	'0012': 'Suboficiales de las fuerzas armadas',
	'0020': 'Tropa y marinería de las fuerzas armadas',
	'1111': 'Miembros del poder ejecutivo (nacional, autonómico y local) y del poder legislativo',
	'1112': 'Personal directivo de la Administración Pública',
	'1113': 'Directores de organizaciones de interés social',
	'1120': 'Directores generales y presidentes ejecutivos',
	'1211': 'Directores financieros',
	'1212': 'Directores de recursos humanos',
	'1219':
		'Directores de políticas y planificación y de otros departamentos administrativos no clasificados bajo otros epígrafes',
	'1221': 'Directores comerciales y de ventas',
	'1222': 'Directores de publicidad y relaciones públicas',
	'1223': 'Directores de investigación y desarrollo',
	'1311': 'Directores de producción de explotaciones agropecuarias y forestales',
	'1312': 'Directores de producción de explotaciones pesqueras y acuícolas',
	'1313': 'Directores de industrias manufactureras',
	'1314': 'Directores de explotaciones mineras',
	'1315': 'Directores de empresas de abastecimiento, transporte, distribución y afines',
	'1316': 'Directores de empresas de construcción',
	'1321': 'Directores de servicios de tecnologías de la información y las comunicaciones (TIC)',
	'1322': 'Directores de servicios sociales para niños',
	'1323': 'Directores-gerentes de centros sanitarios',
	'1324': 'Directores de servicios sociales para personas mayores',
	'1325': 'Directores de otros servicios sociales',
	'1326': 'Directores de servicios de educación',
	'1327': 'Directores de sucursales de bancos, de servicios financieros y de seguros',
	'1329':
		'Directores de otras empresas de servicios profesionales no clasificados bajo otros epígrafes',
	'1411': 'Directores y gerentes de hoteles',
	'1419': 'Directores y gerentes de otras empresas de servicios de alojamiento',
	'1421': 'Directores y gerentes de restaurantes',
	'1422': 'Directores y gerentes de bares, cafeterías y similares',
	'1429': 'Directores y gerentes de empresas de catering y otras empresas de restauración',
	'1431': 'Directores y gerentes de empresas de comercio al por mayor',
	'1432': 'Directores y gerentes de empresas de comercio al por menor',
	'1501': 'Directores y gerentes de empresas de actividades recreativas, culturales y deportivas',
	'1509':
		'Directores y gerentes de empresas de gestión de residuos y de otras empresas de servicios no clasificados bajo otros epígrafes',
	'2111': 'Médicos de familia',
	'2112': 'Otros médicos especialistas',
	'2121': 'Enfermeros no especializados',
	'2122': 'Enfermeros especializados (excepto matronos)',
	'2123': 'Matronos',
	'2130': 'Veterinarios',
	'2140': 'Farmacéuticos',
	'2151': 'Odontólogos y estomatólogos',
	'2152': 'Fisioterapeutas',
	'2153': 'Dietistas y nutricionistas',
	'2154': 'Logopedas',
	'2155': 'Ópticos-optometristas',
	'2156': 'Terapeutas ocupacionales',
	'2157': 'Podólogos',
	'2158': 'Profesionales de la salud y la higiene laboral y ambiental',
	'2159': 'Profesionales de la salud no clasificados bajo otros epígrafes',
	'2210': 'Profesores de universidades y otra enseñanza superior (excepto formación profesional)',
	'2220': 'Profesores de formación profesional (materias específicas)',
	'2230':
		'Profesores de enseñanza secundaria (excepto materias específicas de formación profesional)',
	'2240': 'Profesores de enseñanza primaria',
	'2251': 'Maestros de educación infantil',
	'2252': 'Técnicos en educación infantil',
	'2311': 'Profesores de educación especial',
	'2312': 'Técnicos educadores de educación especial',
	'2321': 'Especialistas en métodos didácticos y pedagógicos',
	'2322': 'Profesores de enseñanza no reglada de idiomas',
	'2323': 'Profesores de enseñanza no reglada de música y danza',
	'2324': 'Profesores de enseñanza no reglada de artes',
	'2325': 'Instructores en tecnologías de la información en enseñanza no reglada',
	'2326': 'Profesionales de la educación ambiental',
	'2329': 'Profesores y profesionales de la enseñanza no clasificados bajo otros epígrafes',
	'2411': 'Físicos y astrónomos',
	'2412': 'Meteorólogos',
	'2413': 'Químicos',
	'2414': 'Geólogos y geofísicos',
	'2415': 'Matemáticos y actuarios',
	'2416': 'Estadísticos',
	'2421': 'Biólogos, botánicos, zoólogos y afines',
	'2422': 'Ingenieros agrónomos',
	'2423': 'Ingenieros de montes',
	'2424': 'Ingenieros técnicos agrícolas',
	'2425': 'Ingenieros técnicos forestales y del medio natural',
	'2426': 'Profesionales de la protección ambiental',
	'2427': 'Enólogos',
	'2431': 'Ingenieros industriales y de producción',
	'2432': 'Ingenieros en construcción y obra civil',
	'2433': 'Ingenieros mecánicos',
	'2434': 'Ingenieros aeronáuticos',
	'2435': 'Ingenieros químicos',
	'2436': 'Ingenieros de minas, metalúrgicos y afines',
	'2437': 'Ingenieros ambientales',
	'2439': 'Ingenieros no clasificados bajo otros epígrafes',
	'2441': 'Ingenieros en electricidad',
	'2442': 'Ingenieros electrónicos',
	'2443': 'Ingenieros en telecomunicaciones',
	'2451': 'Arquitectos (excepto arquitectos paisajistas y urbanistas)',
	'2452': 'Arquitectos paisajistas',
	'2453': 'Urbanistas e ingenieros de tráfico',
	'2454': 'Ingenieros geógrafos y cartógrafos',
	'2461': 'Ingenieros técnicos industriales y de producción',
	'2462': 'Ingenieros técnicos de obras públicas',
	'2463': 'Ingenieros técnicos mecánicos',
	'2464': 'Ingenieros técnicos aeronáuticos',
	'2465': 'Ingenieros técnicos químicos',
	'2466': 'Ingenieros técnicos de minas, metalúrgicos y afines',
	'2469': 'Ingenieros técnicos no clasificados bajo otros epígrafes',
	'2471': 'Ingenieros técnicos en electricidad',
	'2472': 'Ingenieros técnicos en electrónica',
	'2473': 'Ingenieros técnicos en telecomunicaciones',
	'2481': 'Arquitectos técnicos y técnicos urbanistas',
	'2482': 'Diseñadores de productos y de prendas',
	'2483': 'Ingenieros técnicos en topografía',
	'2484': 'Diseñadores gráficos y multimedia',
	'2511': 'Abogados',
	'2512': 'Fiscales',
	'2513': 'Jueces y magistrados',
	'2591': 'Notarios y registradores',
	'2592': 'Procuradores',
	'2599': 'Profesionales del derecho no clasificados bajo otros epígrafes',
	'2611': 'Especialistas en contabilidad',
	'2612': 'Asesores financieros y en inversiones',
	'2613': 'Analistas financieros',
	'2621': 'Analistas de gestión y organización',
	'2622': 'Especialistas en administración de política de empresas',
	'2623': 'Especialistas de la Administración Pública',
	'2624': 'Especialistas en políticas y servicios de personal y afines',
	'2625': 'Especialistas en formación de personal',
	'2630': 'Técnicos de empresas y actividades turísticas',
	'2640': 'Profesionales de ventas técnicas y médicas (excepto las TIC)',
	'2651': 'Profesionales de la publicidad y la comercialización',
	'2652': 'Profesionales de relaciones públicas',
	'2653': 'Profesionales de la venta de tecnologías de la información y las comunicaciones',
	'2711': 'Analistas de sistemas',
	'2712': 'Analistas y diseñadores de software',
	'2713': 'Analistas, programadores y diseñadores Web y multimedia',
	'2719': 'Analistas y diseñadores de software y multimedia no clasificados bajo otros epígrafes',
	'2721': 'Diseñadores y administradores de bases de datos',
	'2722': 'Administradores de sistemas y redes',
	'2723': 'Analistas de redes informáticas',
	'2729':
		'Especialistas en bases de datos y en redes informáticas no clasificados bajo otros epígrafes',
	'2810': 'Economistas',
	'2821': 'Sociólogos, geógrafos, antropólogos, arqueólogos y afines',
	'2822': 'Filósofos, historiadores y profesionales en ciencias políticas',
	'2823': 'Psicólogos',
	'2824': 'Profesionales del trabajo y la educación social',
	'2825': 'Agentes de igualdad de oportunidades entre mujeres y hombres',
	'2830': 'Sacerdotes de las distintas religiones',
	'2911': 'Archivistas y conservadores de museos',
	'2912': 'Bibliotecarios, documentalistas y afines',
	'2921': 'Escritores',
	'2922': 'Periodistas',
	'2923': 'Filólogos, intérpretes y traductores',
	'2931': 'Artistas de artes plásticas y visuales',
	'2932': 'Compositores, músicos y cantantes',
	'2933': 'Coreógrafos y bailarines',
	'2934': 'Directores de cine, de teatro y afines',
	'2935': 'Actores',
	'2936': 'Locutores de radio, televisión y otros presentadores',
	'2937': 'Profesionales de espectáculos taurinos',
	'2939': 'Artistas creativos e interpretativos no clasificados bajo otros epígrafes',
	'3110': 'Delineantes y dibujantes técnicos',
	'3121': 'Técnicos en ciencias físicas y químicas',
	'3122': 'Técnicos en construcción',
	'3123': 'Técnicos en electricidad',
	'3124': 'Técnicos en electrónica (excepto electromedicina)',
	'3125': 'Técnicos en electrónica, especialidad en electromedicina',
	'3126': 'Técnicos en mecánica',
	'3127': 'Técnicos y analistas de laboratorio en química industrial',
	'3128': 'Técnicos en metalurgia y minas',
	'3129':
		'Otros técnicos de las ciencias físicas, químicas, medioambientales y de las ingenierías',
	'3131': 'Técnicos en instalaciones de producción de energía',
	'3132':
		'Técnicos en instalaciones de tratamiento de residuos, de aguas y otros operadores en plantas similares',
	'3133': 'Técnicos en control de instalaciones de procesamiento de productos químicos',
	'3134': 'Técnicos de refinerías de petróleo y gas natural',
	'3135': 'Técnicos en control de procesos de producción de metales',
	'3139': 'Técnicos en control de procesos no clasificados bajo otros epígrafes',
	'3141': 'Técnicos en ciencias biológicas (excepto en áreas sanitarias)',
	'3142': 'Técnicos agropecuarios',
	'3143': 'Técnicos forestales y del medio natural',
	'3151': 'Jefes y oficiales de máquinas',
	'3152': 'Capitanes y oficiales de puente',
	'3153': 'Pilotos de aviación y profesionales afines',
	'3154': 'Controladores de tráfico aéreo',
	'3155': 'Técnicos en seguridad aeronáutica',
	'3160': 'Técnicos de control de calidad de las ciencias físicas, químicas y de las ingenierías',
	'3201': 'Supervisores en ingeniería de minas',
	'3202': 'Supervisores de la construcción',
	'3203': 'Supervisores de industrias alimenticias y del tabaco',
	'3204': 'Supervisores de industrias química y farmacéutica',
	'3205': 'Supervisores de industrias de transformación de plásticos, caucho y resinas naturales',
	'3206': 'Supervisores de industrias de la madera y pastero papeleras',
	'3207':
		'Supervisores de la producción en industrias de artes gráficas y en la fabricación de productos de papel',
	'3209': 'Supervisores de otras industrias manufactureras',
	'3311': 'Técnicos en radioterapia',
	'3312': 'Técnicos en imagen para el diagnóstico',
	'3313': 'Técnicos en anatomía patológica y citología',
	'3314': 'Técnicos en laboratorio de diagnóstico clínico',
	'3315': 'Técnicos en ortoprótesis',
	'3316': 'Técnicos en prótesis dentales',
	'3317': 'Técnicos en audioprótesis',
	'3321': 'Técnicos superiores en higiene bucodental',
	'3322': 'Técnicos superiores en documentación sanitaria',
	'3323': 'Técnicos superiores en dietética',
	'3324': 'Técnicos en optometría',
	'3325': 'Ayudantes fisioterapeutas',
	'3326': 'Técnicos en prevención de riesgos laborales y salud ambiental',
	'3327': 'Ayudantes de veterinaria',
	'3329': 'Técnicos de la sanidad no clasificados bajo otros epígrafes',
	'3331':
		'Profesionales de la acupuntura, la naturopatía, la homeopatía, la medicina tradicional china y la ayurveda',
	'3339': 'Otros profesionales de las terapias alternativas',
	'3401': 'Profesionales de apoyo e intermediarios de cambio, bolsa y finanzas',
	'3402': 'Comerciales de préstamos y créditos',
	'3403': 'Tenedores de libros',
	'3404': 'Profesionales de apoyo en servicios estadísticos, matemáticos y afines',
	'3405': 'Tasadores',
	'3510': 'Agentes y representantes comerciales',
	'3521': 'Mediadores y agentes de seguros',
	'3522': 'Agentes de compras',
	'3523': 'Consignatarios',
	'3531': 'Representantes de aduanas',
	'3532': 'Organizadores de conferencias y eventos',
	'3533':
		'Agentes o intermediarios en la contratación de la mano de obra (excepto representantes de espectáculos)',
	'3534': 'Agentes y administradores de la propiedad inmobiliaria',
	'3535': 'Portavoces y agentes de relaciones públicas',
	'3539':
		'Representantes artísticos y deportivos y otros agentes de servicios comerciales no clasificados bajo otros epígrafes',
	'3611': 'Supervisores de secretaría',
	'3612': 'Asistentes jurídico-legales',
	'3613': 'Asistentes de dirección y administrativos',
	'3614': 'Secretarios de centros médicos o clínicas',
	'3621': 'Profesionales de apoyo de la Administración Pública de tributos',
	'3622': 'Profesionales de apoyo de la Administración Pública de servicios sociales',
	'3623':
		'Profesionales de apoyo de la Administración Pública de servicios de expedición de licencias',
	'3629':
		'Otros profesionales de apoyo de la Administración Pública para tareas de inspección y control y tareas similares',
	'3631': 'Técnicos de la policía nacional, autonómica y local',
	'3632': 'Suboficiales de la guardia civil',
	'3711': 'Profesionales de apoyo de servicios jurídicos y servicios similares',
	'3712': 'Detectives privados',
	'3713': 'Profesionales de apoyo al trabajo y a la educación social',
	'3714': 'Promotores de igualdad de oportunidades entre mujeres y hombres',
	'3715': 'Animadores comunitarios',
	'3716': 'Auxiliares laicos de las religiones',
	'3721': 'Atletas y deportistas',
	'3722': 'Entrenadores y árbitros de actividades deportivas',
	'3723': 'Instructores de actividades deportivas',
	'3724': 'Monitores de actividades recreativas y de entretenimiento',
	'3731': 'Fotógrafos',
	'3732': 'Diseñadores y decoradores de interior',
	'3733': 'Técnicos en galerías de arte, museos y bibliotecas',
	'3734': 'Chefs',
	'3739': 'Otros técnicos y profesionales de apoyo de actividades culturales y artísticas',
	'3811': 'Técnicos en operaciones de sistemas informáticos',
	'3812': 'Técnicos en asistencia al usuario de tecnologías de la información',
	'3813': 'Técnicos en redes',
	'3814': 'Técnicos de la Web',
	'3820': 'Programadores informáticos',
	'3831': 'Técnicos de grabación audiovisual',
	'3832': 'Técnicos de radiodifusión',
	'3833': 'Técnicos de ingeniería de las telecomunicaciones',
	'4111': 'Empleados de contabilidad',
	'4112': 'Empleados de control de personal y nóminas',
	'4113': 'Empleados de oficina de servicios estadísticos, financieros y bancarios',
	'4121': 'Empleados de control de abastecimientos e inventario',
	'4122': 'Empleados de oficina de servicios de apoyo a la producción',
	'4123': 'Empleados de logística y transporte de pasajeros y mercancías',
	'4210': 'Empleados de bibliotecas y archivos',
	'4221': 'Empleados de servicios de correos (excepto empleados de mostrador)',
	'4222': 'Codificadores y correctores de imprenta',
	'4223': 'Empleados de servicio de personal',
	'4301': 'Grabadores de datos',
	'4309':
		'Empleados administrativos sin tareas de atención al público no clasificados bajo otros epígrafes',
	'4411': 'Empleados de información al usuario',
	'4412': 'Recepcionistas (excepto de hoteles)',
	'4421': 'Empleados de agencias de viajes',
	'4422': 'Recepcionistas de hoteles',
	'4423': 'Telefonistas',
	'4424': 'Teleoperadores',
	'4430': 'Agentes de encuestas',
	'4441': 'Cajeros de bancos y afines',
	'4442': 'Empleados de venta de apuestas',
	'4443': 'Empleados de sala de juegos y afines',
	'4444': 'Empleados de casas de empeño y de préstamos',
	'4445': 'Cobradores de facturas, deudas y empleados afines',
	'4446': 'Empleados de mostrador de correos',
	'4500':
		'Empleados administrativos con tareas de atención al público no clasificados bajo otros epígrafes',
	'5000': 'Camareros y cocineros propietarios',
	'5110': 'Cocineros asalariados',
	'5120': 'Camareros asalariados',
	'5210': 'Jefes de sección de tiendas y almacenes',
	'5220': 'Vendedores en tiendas y almacenes',
	'5300': 'Comerciantes propietarios de tiendas',
	'5411': 'Vendedores en quioscos',
	'5412': 'Vendedores en mercados ocasionales y mercadillos',
	'5420': 'Operadores de telemarketing',
	'5430': 'Expendedores de gasolineras',
	'5491': 'Vendedores a domicilio',
	'5492': 'Promotores de venta',
	'5493': 'Modelos de moda, arte y publicidad',
	'5499': 'Vendedores no clasificados bajo otros epígrafes',
	'5500': 'Cajeros y taquilleros (excepto bancos)',
	'5611': 'Auxiliares de enfermería hospitalaria',
	'5612': 'Auxiliares de enfermería de atención primaria',
	'5621': 'Técnicos auxiliares de farmacia',
	'5622': 'Técnicos de emergencias sanitarias',
	'5629':
		'Trabajadores de los cuidados a las personas en servicios de salud no clasificados bajo otros epígrafes',
	'5710': 'Trabajadores de los cuidados personales a domicilio',
	'5721': 'Cuidadores de niños en guarderías y centros educativos',
	'5722': 'Cuidadores de niños en domicilios',
	'5811': 'Peluqueros',
	'5812': 'Especialistas en tratamientos de estética, bienestar y afines',
	'5821': 'Auxiliares de vuelo y camareros de avión, barco y tren',
	'5822': 'Revisores y cobradores de transporte terrestre',
	'5823': 'Acompañantes turísticos',
	'5824': 'Azafatos de tierra',
	'5825': 'Guías de turismo',
	'5831': 'Supervisores de mantenimiento y limpieza en oficinas, hoteles y otros establecimientos',
	'5832': 'Mayordomos del servicio doméstico',
	'5833': 'Conserjes de edificios',
	'5840': 'Trabajadores propietarios de pequeños alojamientos',
	'5891': 'Asistentes personales o personas de compañía',
	'5892': 'Empleados de pompas fúnebres y embalsamadores',
	'5893': 'Cuidadores de animales y adiestradores',
	'5894': 'Instructores de autoescuela',
	'5895': 'Astrólogos, adivinadores y afines',
	'5899': 'Trabajadores de servicios personales no clasificados bajo otros epígrafes',
	'5910': 'Guardias civiles',
	'5921': 'Policías nacionales',
	'5922': 'Policías autonómicos',
	'5923': 'Policías locales',
	'5931': 'Bomberos (excepto forestales)',
	'5932': 'Bomberos forestales',
	'5941': 'Vigilantes de seguridad y similares habilitados para ir armados',
	'5942': 'Auxiliares de vigilante de seguridad y similares no habilitados para ir armados',
	'5991': 'Vigilantes de prisiones',
	'5992': 'Bañistas-socorristas',
	'5993': 'Agentes forestales y medioambientales',
	'5999':
		'Trabajadores de los servicios de protección y seguridad no clasificados bajo otros epígrafes',
	'6110':
		'Trabajadores cualificados en actividades agrícolas (excepto en huertas, invernaderos, viveros y jardines)',
	'6120': 'Trabajadores cualificados en huertas, invernaderos, viveros y jardines',
	'6201': 'Trabajadores cualificados en actividades ganaderas de vacuno',
	'6202': 'Trabajadores cualificados en actividades ganaderas de ovino y caprino',
	'6203': 'Trabajadores cualificados en actividades ganaderas de porcino',
	'6204': 'Trabajadores cualificados en apicultura y sericicultura',
	'6205': 'Trabajadores cualificados en la avicultura y la cunicultura',
	'6209':
		'Trabajadores cualificados en actividades ganaderas no clasificados bajo otros epígrafes',
	'6300': 'Trabajadores cualificados en actividades agropecuarias mixtas',
	'6410': 'Trabajadores cualificados en actividades forestales y del medio natural',
	'6421': 'Trabajadores cualificados en la acuicultura',
	'6422': 'Pescadores de aguas costeras y aguas dulces',
	'6423': 'Pescadores de altura',
	'6430': 'Trabajadores cualificados en actividades cinegéticas',
	'7111': 'Encofradores y operarios de puesta en obra de hormigón',
	'7112': 'Montadores de prefabricados estructurales (sólo hormigón)',
	'7121': 'Albañiles',
	'7122': 'Canteros, tronzadores, labrantes y grabadores de piedras',
	'7131': 'Carpinteros (excepto ebanistas)',
	'7132':
		'Instaladores de cerramientos metálicos y carpinteros metálicos (excepto montadores de estructuras metálicas)',
	'7191': 'Mantenedores de edificios',
	'7192': 'Instaladores de fachadas técnicas',
	'7193': 'Instaladores de sistemas de impermeabilización en edificios',
	'7199':
		'Otros trabajadores de las obras estructurales de construcción no clasificados bajo otros epígrafes',
	'7211': 'Escayolistas',
	'7212': 'Aplicadores de revestimientos de pasta y mortero',
	'7221': 'Fontaneros',
	'7222': 'Montadores-instaladores de gas en edificios',
	'7223': 'Instaladores de conductos en obra pública',
	'7231': 'Pintores y empapeladores',
	'7232': 'Pintores en las industrias manufactureras',
	'7240': 'Soladores, colocadores de parquet y afines',
	'7250': 'Mecánicos-instaladores de refrigeración y climatización',
	'7291': 'Montadores de cubiertas',
	'7292': 'Instaladores de material aislante térmico y de insonorización',
	'7293': 'Cristaleros',
	'7294': 'Montadores-instaladores de placas de energía solar',
	'7295': 'Personal de limpieza de fachadas de edificios y chimeneas',
	'7311': 'Moldeadores y macheros',
	'7312': 'Soldadores y oxicortadores',
	'7313': 'Chapistas y caldereros',
	'7314': 'Montadores de estructuras metálicas',
	'7315': 'Montadores de estructuras cableadas y empalmadores de cables',
	'7321': 'Herreros y forjadores',
	'7322':
		'Trabajadores de la fabricación de herramientas, mecánico-ajustadores, modelistas, matriceros y afines',
	'7323': 'Ajustadores y operadores de máquinas-herramienta',
	'7324': 'Pulidores de metales y afiladores de herramientas',
	'7401': 'Mecánicos y ajustadores de vehículos de motor',
	'7402': 'Mecánicos y ajustadores de motores de avión',
	'7403': 'Mecánicos y ajustadores de maquinaria agrícola e industrial',
	'7404': 'Mecánicos y ajustadores de maquinaria naval y ferroviaria',
	'7405': 'Reparadores de bicicletas y afines',
	'7510': 'Electricistas de la construcción y afines',
	'7521': 'Mecánicos y reparadores de equipos eléctricos',
	'7522': 'Instaladores y reparadores de líneas eléctricas',
	'7531': 'Mecánicos y reparadores de equipos electrónicos',
	'7532': 'Instaladores y reparadores en electromedicina',
	'7533': 'Instaladores y reparadores en tecnologías de la información y las comunicaciones',
	'7611': 'Relojeros y mecánicos de instrumentos de precisión',
	'7612': 'Lutieres y similares; afinadores de instrumentos musicales',
	'7613': 'Joyeros, orfebres y plateros',
	'7614': 'Trabajadores de la cerámica, alfareros y afines',
	'7615': 'Sopladores, modeladores, laminadores, cortadores y pulidores de vidrio',
	'7616': 'Rotulistas, grabadores de vidrio, pintores decorativos de artículos diversos',
	'7617': 'Artesanos en madera y materiales similares; cesteros, bruceros y trabajadores afines',
	'7618':
		'Artesanos en tejidos, cueros y materiales similares, preparadores de fibra y tejedores con telares artesanos o de tejidos de punto y afines',
	'7619': 'Artesanos no clasificados bajo otros epígrafes',
	'7621': 'Trabajadores de procesos de preimpresión',
	'7622': 'Trabajadores de procesos de impresión',
	'7623': 'Trabajadores de procesos de encuadernación',
	'7701': 'Matarifes y trabajadores de las industrias cárnicas',
	'7702': 'Trabajadores de las industrias del pescado',
	'7703': 'Panaderos, pasteleros y confiteros',
	'7704':
		'Trabajadores del tratamiento de la leche y elaboración de productos lácteos (incluidos helados)',
	'7705':
		'Trabajadores conserveros de frutas y hortalizas y trabajadores de la elaboración de bebidas no alcohólicas',
	'7706': 'Trabajadores de la elaboración de bebidas alcohólicas distintas del vino',
	'7707': 'Trabajadores de la elaboración del vino',
	'7708': 'Preparadores y elaboradores del tabaco y sus productos',
	'7709': 'Catadores y clasificadores de alimentos y bebidas',
	'7811': 'Trabajadores del tratamiento de la madera',
	'7812': 'Ajustadores y operadores de máquinas para trabajar la madera',
	'7820': 'Ebanistas y trabajadores afines',
	'7831': 'Sastres, modistos, peleteros y sombrereros',
	'7832': 'Patronistas para productos en textil y piel',
	'7833': 'Cortadores de tejidos, cuero, piel y otros materiales',
	'7834': 'Costureros a mano, bordadores y afines',
	'7835': 'Tapiceros, colchoneros y afines',
	'7836': 'Curtidores y preparadores de pieles',
	'7837': 'Zapateros y afines',
	'7891': 'Buceadores',
	'7892': 'Pegadores',
	'7893': 'Clasificadores y probadores de productos (excepto alimentos, bebidas y tabaco)',
	'7894': 'Fumigadores y otros controladores de plagas y malas hierbas',
	'7899': 'Oficiales, operarios y artesanos de otros oficios no clasificados bajo otros epígrafes',
	'8111': 'Mineros y otros operadores en instalaciones mineras',
	'8112': 'Operadores en instalaciones para la preparación de minerales y rocas',
	'8113': 'Sondistas y trabajadores afines',
	'8114': 'Operadores de maquinaria para fabricar productos derivados de minerales no metálicos',
	'8121': 'Operadores en instalaciones para la obtención y transformación de metales',
	'8122': 'Operadores de máquinas pulidoras, galvanizadoras y recubridoras de metales',
	'8131': 'Operadores en plantas industriales químicas',
	'8132': 'Operadores de máquinas para fabricar productos farmacéuticos, cosméticos y afines',
	'8133': 'Operadores de laboratorios fotográficos y afines',
	'8141':
		'Operadores de máquinas para fabricar productos de caucho y derivados de resinas naturales',
	'8142': 'Operadores de máquinas para fabricar productos de material plástico',
	'8143': 'Operadores de máquinas para fabricar productos de papel y cartón',
	'8144':
		'Operadores de serrerías, de máquinas de fabricación de tableros y de instalaciones afines para el tratamiento de la madera y el corcho',
	'8145':
		'Operadores en instalaciones para la preparación de pasta de papel y fabricación de papel',
	'8151': 'Operadores de máquinas para preparar fibras, hilar y devanar',
	'8152': 'Operadores de telares y otras máquinas tejedoras',
	'8153': 'Operadores de máquinas de coser y bordar',
	'8154': 'Operadores de máquinas de blanquear, teñir, estampar y acabar textiles',
	'8155': 'Operadores de máquinas para tratar pieles y cuero',
	'8156':
		'Operadores de máquinas para la fabricación del calzado, marroquinería y guantería de piel',
	'8159':
		'Operadores de máquinas para fabricar productos textiles no clasificados bajo otros epígrafes',
	'8160': 'Operadores de máquinas para elaborar productos alimenticios, bebidas y tabaco',
	'8170': 'Operadores de máquinas de lavandería y tintorería',
	'8191': 'Operadores de hornos e instalaciones de vidriería y cerámica',
	'8192': 'Operadores de calderas y máquinas de vapor',
	'8193': 'Operadores de máquinas de embalaje, embotellamiento y etiquetado',
	'8199': 'Operadores de instalaciones y maquinaria fijas no clasificados bajo otros epígrafes',
	'8201': 'Ensambladores de maquinaria mecánica',
	'8202': 'Ensambladores de equipos eléctricos y electrónicos',
	'8209': 'Montadores y ensambladores no clasificados en otros epígrafes',
	'8311': 'Maquinistas de locomotoras',
	'8312': 'Agentes de maniobras ferroviarias',
	'8321': 'Operadores de maquinaria agrícola móvil',
	'8322': 'Operadores de maquinaria forestal móvil',
	'8331': 'Operadores de maquinaria de movimientos de tierras y equipos similares',
	'8332': 'Operadores de grúas, montacargas y de maquinaria similar de movimiento de materiales',
	'8333': 'Operadores de carretillas elevadoras',
	'8340': 'Marineros de puente, marineros de máquinas y afines',
	'8411': 'Conductores propietarios de automóviles, taxis y furgonetas',
	'8412': 'Conductores asalariados de automóviles, taxis y furgonetas',
	'8420': 'Conductores de autobuses y tranvías',
	'8431': 'Conductores propietarios de camiones',
	'8432': 'Conductores asalariados de camiones',
	'8440': 'Conductores de motocicletas y ciclomotores',
	'9100': 'Empleados domésticos',
	'9210': 'Personal de limpieza de oficinas, hoteles y otros establecimientos similares',
	'9221': 'Limpiadores en seco a mano y afines',
	'9222': 'Limpiadores de vehículos',
	'9223': 'Limpiadores de ventanas',
	'9229': 'Otro personal de limpieza',
	'9310': 'Ayudantes de cocina',
	'9320': 'Preparadores de comidas rápidas',
	'9410': 'Vendedores callejeros',
	'9420': 'Repartidores de publicidad, limpiabotas y otros trabajadores de oficios callejeros',
	'9431': 'Ordenanzas',
	'9432': 'Mozos de equipaje y afines',
	'9433': 'Repartidores, recadistas y mensajeros a pie',
	'9434': 'Lectores de contadores y recaudadores de máquinas recreativas y expendedoras',
	'9441': 'Recogedores de residuos',
	'9442': 'Clasificadores de desechos, operarios de punto limpio y recogedores de chatarra',
	'9443': 'Barrenderos y afines',
	'9490': 'Otras ocupaciones elementales',
	'9511': 'Peones agrícolas (excepto en huertas, invernaderos, viveros y jardines)',
	'9512': 'Peones agrícolas en huertas, invernaderos, viveros y jardines',
	'9520': 'Peones ganaderos',
	'9530': 'Peones agropecuarios',
	'9541': 'Peones de la pesca',
	'9542': 'Peones de la acuicultura',
	'9543': 'Peones forestales y de la caza',
	'9601': 'Peones de obras públicas',
	'9602': 'Peones de la construcción de edificios',
	'9603': 'Peones de la minería, canteras y otras industrias extractivas',
	'9700': 'Peones de las industrias manufactureras',
	'9811': 'Peones del transporte de mercancías y descargadores',
	'9812': 'Conductores de vehículos de tracción animal para el transporte de personas y similares',
	'9820': 'Reponedores',
};

export const COMBO_CNAE = {
	'0111': 'Cultivo de cereales (excepto arroz), leguminosas y semillas oleaginosas',
	'0112': 'Cultivo de arroz',
	'0113': 'Cultivo de hortalizas, raíces y tubérculos',
	'0114': 'Cultivo de caña de azúcar',
	'0115': 'Cultivo de tabaco',
	'0116': 'Cultivo de plantas para fibras textiles',
	'0119': 'Otros cultivos no perennes',
	'0121': 'Cultivo de la vid',
	'0122': 'Cultivo de frutos tropicales y subtropicales',
	'0123': 'Cultivo de cítricos',
	'0124': 'Cultivo de frutos con hueso y pepitas',
	'0125': 'Cultivo de otros árboles y arbustos frutales y frutos secos',
	'0126': 'Cultivo de frutos oleaginosos',
	'0127': 'Cultivo de plantas para bebidas',
	'0128': 'Cultivo de especias, plantas aromáticas, medicinales y farmacéuticas',
	'0129': 'Otros cultivos perennes',
	'0130': 'Propagación de plantas',
	'0141': 'Explotación de ganado bovino para la producción de leche',
	'0142': 'Explotación de otro ganado bovino y búfalos',
	'0143': 'Explotación de caballos y otros equinos',
	'0144': 'Explotación de camellos y otros camélidos',
	'0145': 'Explotación de ganado ovino y caprino',
	'0146': 'Explotación de ganado porcino',
	'0147': 'Avicultura',
	'0149': 'Otras explotaciones de ganado',
	'0150': 'Producción agrícola combinada con la producción ganadera',
	'0161': 'Actividades de apoyo a la agricultura',
	'0162': 'Actividades de apoyo a la ganadería',
	'0163': 'Actividades de preparación posterior a la cosecha',
	'0164': 'Tratamiento de semillas para reproducción',
	'0170': 'Caza, captura de animales y servicios relacionados con las mismas',
	'0210': 'Silvicultura y otras actividades forestales',
	'0220': 'Explotación de la madera',
	'0230': 'Recolección de productos silvestres, excepto madera',
	'0240': 'Servicios de apoyo a la silvicultura',
	'0311': 'Pesca marina',
	'0312': 'Pesca en agua dulce',
	'0321': 'Acuicultura marina',
	'0322': 'Acuicultura en agua dulce',
	'3512': 'Transporte de energía eléctrica',
	'3513': 'Distribución de energía eléctrica',
	'3514': 'Comercio de energía eléctrica',
	'3515': 'Producción de energía hidroeléctrica',
	'3516': 'Producción de energía eléctrica de origen térmico convencional',
	'3517': 'Producción de energía eléctrica de origen nuclear',
	'3518': 'Producción de energía eléctrica de origen eólico',
	'3519': 'Producción de energía eléctrica de otros tipos',
	'3521': 'Producción de gas',
	'3522': 'Distribución por tubería de combustibles gaseosos',
	'3523': 'Comercio de gas por tubería',
	'3530': 'Suministro de vapor y aire acondicionado',
	'3600': 'Captación, depuración y distribución de agua',
	'3700': 'Recogida y tratamiento de aguas residuales',
	'3811': 'Recogida de residuos no peligrosos',
	'3812': 'Recogida de residuos peligrosos',
	'3821': 'Tratamiento y eliminación de residuos no peligrosos',
	'3822': 'Tratamiento y eliminación de residuos peligrosos',
	'3831': 'Separación y clasificación de materiales',
	'3832': 'Valorización de materiales ya clasificados',
	'3900': 'Actividades de descontaminación y otros servicios de gestión de residuos',
	'4110': 'Promoción inmobiliaria',
	'4121': 'Construcción de edificios residenciales',
	'4122': 'Construcción de edificios no residenciales',
	'4211': 'Construcción de carreteras y autopistas',
	'4212': 'Construcción de vías férreas de superficie y subterráneas',
	'4213': 'Construcción de puentes y túneles',
	'4221': 'Construcción de redes para fluidos',
	'4222': 'Construcción de redes eléctricas y de telecomunicaciones',
	'4291': 'Obras hidráulicas',
	'4299': 'Construcción de otros proyectos de ingeniería civil n.c.o.p.',
	'4311': 'Demolición',
	'4312': 'Preparación de terrenos',
	'4313': 'Perforaciones y sondeos',
	'4321': 'Instalaciones eléctricas',
	'4322': 'Fontanería, instalaciones de sistemas de calefacción y aire acondicionado',
	'4329': 'Otras instalaciones en obras de construcción',
	'4331': 'Revocamiento',
	'4332': 'Instalación de carpintería',
	'4333': 'Revestimiento de suelos y paredes',
	'4334': 'Pintura y acristalamiento',
	'4339': 'Otro acabado de edificios',
	'4391': 'Construcción de cubiertas',
	'4399': 'Otras actividades de construcción especializada n.c.o.p.',
	'4511': 'Venta de automóviles y vehículos de motor ligeros',
	'4519': 'Venta de otros vehículos de motor',
	'4520': 'Mantenimiento y reparación de vehículos de motor',
	'4531': 'Comercio al por mayor de repuestos y accesorios de vehículos de motor',
	'4532': 'Comercio al por menor de repuestos y accesorios de vehículos de motor',
	'4540': 'Venta, mantenimiento y reparación de motocicletas y de sus repuestos y accesorios',
	'4611':
		'Intermediarios del comercio de materias primas agrarias, animales vivos, materias primas textiles y productos semielaborados',
	'4612':
		'Intermediarios del comercio de combustibles, minerales, metales y productos químicos industriales',
	'4613': 'Intermediarios del comercio de la madera y materiales de construcción',
	'4614':
		'Intermediarios del comercio de maquinaria, equipo industrial, embarcaciones y aeronaves',
	'4615': 'Intermediarios del comercio de muebles, artículos para el hogar y ferretería',
	'4616':
		'Intermediarios del comercio de textiles, prendas de vestir, peletería, calzado y artículos de cuero',
	'4617': 'Intermediarios del comercio de productos alimenticios, bebidas y tabaco',
	'4618': 'Intermediarios del comercio especializados en la venta de otros productos específicos',
	'4619': 'Intermediarios del comercio de productos diversos',
	'4621': 'Comercio al por mayor de cereales, tabaco en rama, simientes y alimentos para animales',
	'4622': 'Comercio al por mayor de flores y plantas',
	'4623': 'Comercio al por mayor de animales vivos',
	'4624': 'Comercio al por mayor de cueros y pieles',
	'4631': 'Comercio al por mayor de frutas y hortalizas',
	'4632': 'Comercio al por mayor de carne y productos cárnicos',
	'4633': 'Comercio al por mayor de productos lácteos, huevos, aceites y grasas comestibles',
	'4634': 'Comercio al por mayor de bebidas',
	'4635': 'Comercio al por mayor de productos del tabaco',
	'4636': 'Comercio al por mayor de azúcar, chocolate y confitería',
	'4637': 'Comercio al por mayor de café, té, cacao y especias',
	'4638': 'Comercio al por mayor de pescados y mariscos y otros productos alimenticios',
	'4639': 'Comercio al por mayor, no especializado, de productos alimenticios, bebidas y tabaco',
	'4641': 'Comercio al por mayor de textiles',
	'4642': 'Comercio al por mayor de prendas de vestir y calzado',
	'4643': 'Comercio al por mayor de aparatos electrodomésticos',
	'4644': 'Comercio al por mayor de porcelana, cristalería y artículos de limpieza',
	'4645': 'Comercio al por mayor de productos perfumería y cosmética',
	'4646': 'Comercio al por mayor de productos farmacéuticos',
	'4647': 'Comercio al por mayor de muebles, alfombras y aparatos de iluminación',
	'4648': 'Comercio al por mayor de artículos de relojería y joyería',
	'4649': 'Comercio al por mayor de otros artículos de uso doméstico',
	'4651': 'Comercio al por mayor de ordenadores, equipos periféricos y programas informáticos',
	'4652':
		'Comercio al por mayor de equipos electrónicos y de telecomunicaciones y sus componentes',
	'4661': 'Comercio al por mayor de maquinaria, equipos y suministros agrícolas',
	'4662': 'Comercio al por mayor de máquinas herramienta',
	'4663':
		'Comercio al por mayor de maquinaria para la minería, la construcción y la ingeniería civil',
	'4664':
		'Comercio al por mayor de maquinaria para la industria textil y de máquinas de coser y tricotar',
	'4665': 'Comercio al por mayor de muebles de oficina',
	'4666': 'Comercio al por mayor de otra maquinaria y equipo de oficina',
	'4669': 'Comercio al por mayor de otra maquinaria y equipo',
	'4671':
		'Comercio al por mayor de combustibles sólidos, líquidos y gaseosos, y productos similares',
	'4672': 'Comercio al por mayor de metales y minerales metálicos',
	'4673': 'Comercio al por mayor de madera, materiales de construcción y aparatos sanitarios',
	'4674': 'Comercio al por mayor de ferretería, fontanería y calefacción',
	'4675': 'Comercio al por mayor de productos químicos',
	'4676': 'Comercio al por mayor de otros productos semielaborados',
	'4677': 'Comercio al por mayor de chatarra y productos de desecho',
	'4690': 'Comercio al por mayor no especializado',
	'4711':
		'Comercio al por menor en establecimientos no especializados, con predominio en productos alimenticios, bebidas y tabaco',
	'4719': 'Otro comercio al por menor en establecimientos no especializados',
	'4721': 'Comercio al por menor de frutas y hortalizas en establecimientos especializados',
	'4722': 'Comercio al por menor de carne y productos cárnicos en establecimientos especializados',
	'4723': 'Comercio al por menor de pescados y mariscos en establecimientos especializados',
	'4724':
		'Comercio al por menor de pan y productos de panadería, confitería y pastelería en establecimientos especializados',
	'4725': 'Comercio al por menor de bebidas en establecimientos especializados',
	'4726': 'Comercio al por menor de productos de tabaco en establecimientos especializados',
	'4729':
		'Otro comercio al por menor de productos alimenticios en establecimientos especializados',
	'4730':
		'Comercio al por menor de combustible para la automoción en establecimientos especializados',
	'4741':
		'Comercio al por menor de ordenadores, equipos periféricos y programas informáticos en establecimientos especializados',
	'4742':
		'Comercio al por menor de equipos de telecomunicaciones en establecimientos especializados',
	'4743': 'Comercio al por menor de equipos de audio y vídeo en establecimientos especializados',
	'4751': 'Comercio al por menor de textiles en establecimientos especializados',
	'4752':
		'Comercio al por menor de ferretería, pintura y vidrio en establecimientos especializados',
	'4753':
		'Comercio al por menor de alfombras, moquetas y revestimientos de paredes y suelos en establecimientos especializados',
	'4754': 'Comercio al por menor de aparatos electrodomésticos en establecimientos especializados',
	'4759':
		'Comercio al por menor de muebles, aparatos de iluminación y otros artículos de uso doméstico en establecimientos especializados',
	'4761': 'Comercio al por menor de libros en establecimientos especializados',
	'4762':
		'Comercio al por menor de periódicos y artículos de papelería en establecimientos especializados',
	'4763':
		'Comercio al por menor de grabaciones de música y vídeo en establecimientos especializados',
	'4764': 'Comercio al por menor de artículos deportivos en establecimientos especializados',
	'4765': 'Comercio al por menor de juegos y juguetes en establecimientos especializados',
	'4771': 'Comercio al por menor de prendas de vestir en establecimientos especializados',
	'4772':
		'Comercio al por menor de calzado y artículos de cuero en establecimientos especializados',
	'4773': 'Comercio al por menor de productos farmacéuticos en establecimientos especializados',
	'4774':
		'Comercio al por menor de artículos médicos y ortopédicos en establecimientos especializados',
	'4775':
		'Comercio al por menor de productos cosméticos e higiénicos en establecimientos especializados',
	'4776':
		'Comercio al por menor de flores, plantas, semillas, fertilizantes, animales de compañía y alimentos para los mismos en establecimientos especializados',
	'4777':
		'Comercio al por menor de artículos de relojería y joyería en establecimientos especializados',
	'4778': 'Otro comercio al por menor de artículos nuevos en establecimientos especializados',
	'4779': 'Comercio al por menor de artículos de segunda mano en establecimientos',
	'4781':
		'Comercio al por menor de productos alimenticios, bebidas y tabaco en puestos de venta y en mercadillos',
	'4782':
		'Comercio al por menor de productos textiles, prendas de vestir y calzado en puestos de venta y en mercadillos',
	'4789': 'Comercio al por menor de otros productos en puestos de venta y en mercadillos',
	'4791': 'Comercio al por menor por correspondencia o Internet',
	'4799':
		'Otro comercio al por menor no realizado ni en establecimientos, ni en puestos de venta ni en mercadillos',
	'4910': 'Transporte interurbano de pasajeros por ferrocarril',
	'4920': 'Transporte de mercancías por ferrocarril',
	'4931': 'Transporte terrestre urbano y suburbano de pasajeros',
	'4932': 'Transporte por taxi',
	'4939': 'tipos de transporte terrestre de pasajeros n.c.o.p.',
	'4941': 'Transporte de mercancías por carretera',
	'4942': 'Servicios de mudanza',
	'4950': 'Transporte por tubería',
	'5010': 'Transporte marítimo de pasajeros',
	'5020': 'Transporte marítimo de mercancías',
	'5030': 'Transporte de pasajeros por vías navegables interiores',
	'5040': 'Transporte de mercancías por vías navegables interiores',
	'5110': 'Transporte aéreo de pasajeros',
	'5121': 'Transporte aéreo de mercancías',
	'5122': 'Transporte espacial',
	'5210': 'Depósito y almacenamiento',
	'5221': 'Actividades anexas al transporte terrestre',
	'5222': 'Actividades anexas al transporte marítimo y por vías navegables interiores',
	'5223': 'Actividades anexas al transporte aéreo',
	'5224': 'Manipulación de mercancías',
	'5229': 'Otras actividades anexas al transporte',
	'5310': 'Actividades postales sometidas a la obligación del servicio universal',
	'5320': 'Otras actividades postales y de correos',
	'5510': 'Hoteles y alojamientos similares',
	'5520': 'Alojamientos turísticos y otros alojamientos de corta estancia',
	'5530': 'Campings y aparcamientos para caravanas',
	'5590': 'Otros alojamientos',
	'5610': 'Restaurantes y puestos de comidas',
	'5621': 'Provisión de comidas preparadas para eventos',
	'5629': 'Otros servicios de comidas',
	'5630': 'Establecimientos de bebidas',
	'5811': 'Edición de libros',
	'5812': 'Edición de directorios y guías de direcciones postales',
	'5813': 'Edición de periódicos',
	'5814': 'Edición de revistas',
	'5819': 'Otras actividades editoriales',
	'5821': 'Edición de videojuegos',
	'5829': 'Edición de otros programas informáticos',
	'5912': 'Actividades de postproducción cinematográfica, de vídeo y de programas de televisión',
	'5914': 'Actividades de exhibición cinematográfica',
	'5915': 'Actividades de producción cinematográfica y de vídeo',
	'5916': 'Actividades de producciones de programas de televisión',
	'5917': 'Actividades de distribución cinematográfica y de vídeo',
	'5918': 'Actividades de distribución de programas de televisión',
	'5920': 'Actividades de grabación de sonido y edición musical',
	'6010': 'Actividades de radiodifusión',
	'6020': 'Actividades de programación y emisión de televisión',
	'6110': 'Telecomunicaciones por cable',
	'6120': 'Telecomunicaciones inalámbricas',
	'6130': 'Telecomunicaciones por satélite',
	'6190': 'Otras actividades de telecomunicaciones',
	'6201': 'Actividades de programación informática',
	'6202': 'Actividades de consultoría informática',
	'6203': 'Gestión de recursos informáticos',
	'6209': 'Otros servicios relacionados con las tecnologías de la información y la informática',
	'6311': 'Proceso de datos, hosting y actividades relacionadas',
	'6312': 'Portales web',
	'6391': 'Actividades de las agencias de noticias',
	'6399': 'Otros servicios de información n.c.o.p.',
	'6411': 'Banco central',
	'6419': 'Otra intermediación monetaria',
	'6420': 'Actividades de las sociedades holding',
	'6430': 'Inversión colectiva, fondos y entidades financieras similares',
	'6491': 'Arrendamiento financiero',
	'6492': 'Otras actividades crediticias',
	'6499': 'Otros servicios financieros, excepto seguros y fondos de pensiones n.c.o.p.',
	'6511': 'Seguros de vida',
	'6512': 'Seguros distintos de los seguros de vida',
	'6520': 'Reaseguros',
	'6530': 'Fondos de pensiones',
	'6611': 'Administración de mercados financieros',
	'6612': 'Actividades de intermediación en operaciones con valores y otros activos',
	'6619':
		'Otras actividades auxiliares a los servicios financieros, excepto seguros y fondos de pensiones',
	'6621': 'Evaluación de riesgos y daños',
	'6622': 'Actividades de agentes y corredores de seguros',
	'6629': 'Otras actividades auxiliares a seguros y fondos de pensiones',
	'6630': 'Actividades de gestión de fondos',
	'6910': 'Actividades jurídicas',
	'6920': 'Actividades de contabilidad, teneduría de libros, auditoría y asesoría fiscal',
	'7010': 'Actividades de las sedes centrales',
	'7021': 'Relaciones públicas y comunicación',
	'7022': 'Otras actividades de consultoría de gestión empresarial',
	'7111': 'Servicios técnicos de arquitectura',
	'7112':
		'Servicios técnicos de ingeniería y otras actividades relacionadas con el asesoramiento técnico',
	'7120': 'Ensayos y análisis técnicos',
	'7211': 'Investigación y desarrollo experimental en biotecnología',
	'7219': 'Otra investigación y desarrollo experimental en ciencias naturales y técnicas',
	'7220': 'Investigación y desarrollo experimental en ciencias sociales y humanidades',
	'7311': 'Agencias de publicidad',
	'7312': 'Servicios de representación de medios de comunicación',
	'7320': 'Estudio de mercado y realización de encuestas de opinión pública',
	'7410': 'Actividades de diseño especializado',
	'7420': 'Actividades de fotografía',
	'7430': 'Actividades de traducción e interpretación',
	'7490': 'Otras actividades profesionales, científicas y técnicas n.c.o.p.',
	'7500': 'Actividades veterinarias',
	'8411': 'Actividades generales de la Administración Pública',
	'8412':
		'Regulación de las actividades sanitarias, educativas y culturales y otros servicios sociales, excepto Seguridad Social',
	'8413': 'Regulación de la actividad económica y contribución a su mayor eficiencia',
	'8421': 'Asuntos exteriores',
	'8422': 'Defensa',
	'8423': 'Justicia',
	'8424': 'Orden público y seguridad',
	'8425': 'Protección civil',
	'8430': 'Seguridad Social obligatoria',
	'9001': 'Artes escénicas',
	'9002': 'Actividades auxiliares a las artes escénicas',
	'9003': 'Creación artística y literaria',
	'9004': 'Gestión de salas de espectáculos',
	'9102': 'Actividades de museos',
	'9103': 'Gestión de lugares y edificios históricos',
	'9104': 'Actividades de los jardines botánicos, parques zoológicos y reservas naturales',
	'9105': 'Actividades de bibliotecas',
	'9106': 'Actividades de archivos',
	'9200': 'Actividades de juegos de azar y apuestas',
	'9311': 'Gestión de instalaciones deportivas',
	'9312': 'Actividades de los clubes deportivos',
	'9313': 'Actividades de los gimnasios',
	'9319': 'Otras actividades deportivas',
	'9321': 'Actividades de los parques de atracciones y los parques temáticos',
	'9329': 'Otras actividades recreativas y de entretenimiento',
	'9411': 'Actividades de organizaciones empresariales y patronales',
	'9412': 'Actividades de organizaciones profesionales',
	'9420': 'Actividades sindicales',
	'9491': 'Actividades de organizaciones religiosas',
	'9492': 'Actividades de organizaciones políticas',
	'9499': 'Otras actividades asociativas n.c.o.p.',
	'9511': 'Reparación de ordenadores y equipos periféricos',
	'9512': 'Reparación de equipos de comunicación',
	'9521': 'Reparación de aparatos electrónicos de audio y vídeo de uso doméstico',
	'9522': 'Reparación de aparatos electrodomésticos y de equipos para el hogar y el jardín',
	'9523': 'Reparación de calzado y artículos de cuero',
	'9524': 'Reparación de muebles y artículos de menaje',
	'9525': 'Reparación de relojes y joyería',
	'9529': 'Reparación de otros efectos personales y artículos de uso doméstico',
	'9601': 'Lavado y limpieza de prendas textiles y de piel',
	'9602': 'Peluquería y otros tratamientos de belleza',
	'9603': 'Pompas fúnebres y actividades relacionadas',
	'9604': 'Actividades de mantenimiento físico',
	'9609': 'Otras servicios personales n.c.o.p.',
	'0510': 'Extracción de antracita y hulla',
	'0520': 'Extracción de lignito',
	'0610': 'Extracción de crudo de petróleo',
	'0620': 'Extracción de gas natural',
	'0710': 'Extracción de minerales de hierro',
	'0721': 'Extracción de minerales de uranio y torio',
	'0729': 'Extracción de otros minerales metálicos no férreos',
	'0811':
		'Extracción de piedra ornamental y para la construcción, piedra caliza, yeso, creta y pizarra',
	'0812': 'Extracción de gravas y arenas; extracción de arcilla y caolín',
	'0891': 'Extracción de minerales para productos químicos y fertilizantes',
	'0892': 'Extracción de turba',
	'0893': 'Extracción de sal',
	'0899': 'Otras industrias extractivas n.c.o.p.',
	'0910': 'Actividades de apoyo a la extracción de petróleo y gas natural',
	'0990': 'Actividades de apoyo a otras industrias extractivas',
	'1011': 'Procesado y conservación de carne',
	'1012': 'Procesado y conservación de volatería',
	'1013': 'Elaboración de productos cárnicos y de volatería',
	'1021': 'Procesado de pescados, crustáceos y moluscos',
	'1022': 'Fabricación de conservas de pescado',
	'1031': 'Procesado y conservación de patatas',
	'1032': 'Elaboración de zumos de frutas y hortalizas',
	'1039': 'Otro procesado y conservación de frutas y hortalizas',
	'1042': 'Fabricación de margarina y grasas comestibles similares',
	'1043': 'Fabricación de aceite de oliva',
	'1044': 'Fabricación de otros aceites y grasas',
	'1052': 'Elaboración de helados',
	'1053': 'Fabricación de quesos',
	'1054': 'Preparación de leche y otros productos lácteos',
	'1061': 'Fabricación de productos de molinería',
	'1062': 'Fabricación de almidones y productos amiláceos',
	'1071': 'Fabricación de pan y de productos frescos de panadería y pastelería',
	'1072': 'Fabricación de galletas y productos de panadería y pastelería de larga duración',
	'1073': 'Fabricación de pastas alimenticias, cuscús y productos similares',
	'1081': 'Fabricación de azúcar',
	'1082': 'Fabricación de cacao, chocolate y productos de confitería',
	'1083': 'Elaboración de café, té e infusiones',
	'1084': 'Elaboración de especias, salsas y condimentos',
	'1085': 'Elaboración de platos y comidas preparados',
	'1086': 'Elaboración de preparados alimenticios homogeneizados y alimentos dietéticos',
	'1089': 'Elaboración de otros productos alimenticios n.c.o.p.',
	'1091': 'Fabricación de productos para la alimentación de animales de granja',
	'1092': 'Fabricación de productos para la alimentación de animales de compañía',
	'1101': 'Destilación, rectificación y mezcla de bebidas alcohólicas',
	'1102': 'Elaboración de vinos',
	'1103': 'Elaboración de sidra y otras bebidas fermentadas a partir de frutas',
	'1104': 'Elaboración de otras bebidas no destiladas, procedentes de la fermentación',
	'1105': 'Fabricación de cerveza',
	'1106': 'Fabricación de malta',
	'1107':
		'Fabricación de bebidas no alcohólicas; producción de aguas minerales y otras aguas embotelladas',
	'1200': 'Industria del tabaco',
	'1310': 'Preparación e hilado de fibras textiles',
	'1320': 'Fabricación de tejidos textiles',
	'1330': 'Acabado de textiles',
	'1391': 'Fabricación de tejidos de punto',
	'1392': 'Fabricación de artículos confeccionados con textiles, excepto prendas de vestir',
	'1393': 'Fabricación de alfombras y moquetas',
	'1394': 'Fabricación de cuerdas, cordeles, bramantes y redes',
	'1395':
		'Fabricación de telas no tejidas y artículos confeccionados con ellas, excepto prendas de vestir',
	'1396': 'Fabricación de otros productos textiles de uso técnico e industrial',
	'1399': 'Fabricación de otros productos textiles n.c.o.p.',
	'1411': 'Confección de prendas de vestir de cuero',
	'1412': 'Confección de ropa de trabajo',
	'1413': 'Confección de otras prendas de vestir exteriores',
	'1414': 'Confección de ropa interior',
	'1419': 'Confección de otras prendas de vestir y accesorios',
	'1420': 'Fabricación de artículos de peletería',
	'1431': 'Confección de calcetería',
	'1439': 'Confección de otras prendas de vestir de punto',
	'1511': 'Preparación, curtido y acabado del cuero; preparación y teñido de pieles',
	'1512': 'Fabricación de artículos de marroquinería, viaje y de guarnicionería y talabartería',
	'1520': 'Fabricación de calzado',
	'1610': 'Aserrado y cepillado de la madera',
	'1621': 'Fabricación de chapas y tableros de madera',
	'1622': 'Fabricación de suelos de madera ensamblados',
	'1623':
		'Fabricación de otras estructuras de madera y piezas de carpintería y ebanistería para la construcción',
	'1624': 'Fabricación de envases y embalajes de madera',
	'1629': 'Fabricación de otros productos de madera; artículos de corcho, cestería y espartería',
	'1711': 'Fabricación de pasta papelera',
	'1712': 'Fabricación de papel y cartón',
	'1721':
		'Fabricación de papel y cartón ondulados; fabricación de envases y embalajes de papel y cartón',
	'1722': 'Fabricación de artículos de papel y cartón para uso doméstico, sanitario e higiénico',
	'1723': 'Fabricación de artículos de papelería',
	'1724': 'Fabricación de papeles pintados',
	'1729': 'Fabricación de otros artículos de papel y cartón',
	'1811': 'Artes gráficas y servicios relacionados con las mismas',
	'1812': 'Otras actividades de impresión y artes gráficas',
	'1813': 'Servicios de preimpresión y preparación de soportes',
	'1814': 'Encuadernación y servicios relacionados con la misma',
	'1820': 'Reproducción de soportes grabados',
	'1910': 'Coquerías',
	'1920': 'Refino de petróleo',
	'2011': 'Fabricación de gases industriales',
	'2012': 'Fabricación de colorantes y pigmentos',
	'2013': 'Fabricación de otros productos básicos de química inorgánica',
	'2014': 'Fabricación de otros productos básicos de química orgánica',
	'2015': 'Fabricación de fertilizantes y compuestos nitrogenados',
	'2016': 'Fabricación de plásticos en formas primarias',
	'2017': 'Fabricación de caucho sintético en formas primarias',
	'2020': 'Fabricación de pesticidas y otros productos agroquímicos',
	'2030':
		'Fabricación de pinturas, barnices y revestimientos similares; tintas de imprenta y masillas',
	'2041': 'Fabricación de jabones, detergentes y otros artículos de limpieza y abrillantamiento',
	'2042': 'Fabricación de perfumes y cosméticos',
	'2051': 'Fabricación de explosivos',
	'2052': 'Fabricación de colas',
	'2053': 'Fabricación de aceites esenciales',
	'2059': 'Fabricación de otros productos químicos n.c.o.p.',
	'2060': 'Fabricación de fibras artificiales y sintéticas',
	'2110': 'Fabricación de productos farmacéuticos de base',
	'2120': 'Fabricación de especialidades farmacéuticas',
	'2211':
		'Fabricación de neumáticos y cámaras de caucho; reconstrucción y recauchutado de neumáticos',
	'2219': 'Fabricación de otros productos de caucho',
	'2221': 'Fabricación de placas, hojas, tubos y perfiles de plástico',
	'2222': 'Fabricación de envases y embalajes de plástico',
	'2223': 'Fabricación de productos de plástico para la construcción',
	'2229': 'Fabricación de otros productos de plástico',
	'2311': 'Fabricación de vidrio plano',
	'2312': 'Manipulado y transformación de vidrio plano',
	'2313': 'Fabricación de vidrio hueco',
	'2314': 'Fabricación de fibra de vidrio',
	'2319': 'Fabricación y manipulado de otro vidrio, incluido el vidrio técnico',
	'2320': 'Fabricación de productos cerámicos refractarios',
	'2331': 'Fabricación de azulejos y baldosas de cerámica',
	'2332': 'Fabricación de ladrillos, tejas y productos de tierras cocidas para la construcción',
	'2341': 'Fabricación de artículos cerámicos de uso doméstico y ornamental',
	'2342': 'Fabricación de aparatos sanitarios cerámicos',
	'2343': 'Fabricación de aisladores y piezas aislantes de material cerámico',
	'2344': 'Fabricación de otros productos cerámicos de uso técnico',
	'2349': 'Fabricación de otros productos cerámicos',
	'2351': 'Fabricación de cemento',
	'2352': 'Fabricación de cal y yeso',
	'2361': 'Fabricación de elementos de hormigón para la construcción',
	'2362': 'Fabricación de elementos de yeso para la construcción',
	'2363': 'Fabricación de hormigón fresco',
	'2364': 'Fabricación de mortero',
	'2365': 'Fabricación de fibrocemento',
	'2369': 'Fabricación de otros productos de hormigón, yeso y cemento',
	'2370': 'Corte, tallado y acabado de la piedra',
	'2391': 'Fabricación de productos abrasivos',
	'2399': 'Fabricación de otros productos minerales no metálicos n.c.o.p.',
	'2410': 'Fabricación de productos básicos de hierro, acero y ferroaleaciones',
	'2420': 'Fabricación de tubos, tuberías, perfiles huecos y sus accesorios, de acero',
	'2431': 'Estirado en frío',
	'2432': 'Laminación en frío',
	'2433': 'Producción de perfiles en frío por conformación con plegado',
	'2434': 'Trefilado en frío',
	'2441': 'Producción de metales preciosos',
	'2442': 'Producción de aluminio',
	'2443': 'Producción de plomo, zinc y estaño',
	'2444': 'Producción de cobre',
	'2445': 'Producción de otros metales no férreos',
	'2446': 'Procesamiento de combustibles nucleares',
	'2451': 'Fundición de hierro',
	'2452': 'Fundición de acero',
	'2453': 'Fundición de metales ligeros',
	'2454': 'Fundición de otros metales no férreos',
	'2511': 'Fabricación de estructuras metálicas y sus componentes',
	'2512': 'Fabricación de carpintería metálica',
	'2521': 'Fabricación de radiadores y calderas para calefacción central',
	'2529': 'Fabricación de otras cisternas, grandes depósitos y contenedores de metal',
	'2530': 'Fabricación de generadores de vapor, excepto calderas de calefacción central',
	'2540': 'Fabricación de armas y municiones',
	'2550': 'Forja, estampación y embutición de metales; metalurgia de polvos',
	'2561': 'Tratamiento y revestimiento de metales',
	'2562': 'Ingeniería mecánica por cuenta de terceros',
	'2571': 'Fabricación de artículos de cuchillería y cubertería',
	'2572': 'Fabricación de cerraduras y herrajes',
	'2573': 'Fabricación de herramientas',
	'2591': 'Fabricación de bidones y toneles de hierro o acero',
	'2592': 'Fabricación de envases y embalajes metálicos ligeros',
	'2593': 'Fabricación de productos de alambre, cadenas y muelles',
	'2594': 'Fabricación de pernos y productos de tornillería',
	'2599': 'Fabricación de otros productos metálicos n.c.o.p.',
	'2611': 'Fabricación de componentes electrónicos',
	'2612': 'Fabricación de circuitos impresos ensamblados',
	'2620': 'Fabricación de ordenadores y equipos periféricos',
	'2630': 'Fabricación de equipos de telecomunicaciones',
	'2640': 'Fabricación de productos electrónicos de consumo',
	'2651': 'Fabricación de instrumentos y aparatos de medida, verificación y navegación',
	'2652': 'Fabricación de relojes',
	'2660': 'Fabricación de equipos de radiación, electromédicos y electroterapéuticos',
	'2670': 'Fabricación de instrumentos de óptica y equipo fotográfico',
	'2680': 'Fabricación de soportes magnéticos y ópticos',
	'2711': 'Fabricación de motores, generadores y transformadores eléctricos',
	'2712': 'Fabricación de aparatos de distribución y control eléctrico',
	'2720': 'Fabricación de pilas y acumuladores eléctricos',
	'2731': 'Fabricación de cables de fibra óptica',
	'2732': 'Fabricación de otros hilos y cables electrónicos y eléctricos',
	'2733': 'Fabricación de dispositivos de cableado',
	'2740': 'Fabricación de lámparas y aparatos eléctricos de iluminación',
	'2751': 'Fabricación de electrodomésticos',
	'2752': 'Fabricación de aparatos domésticos no eléctricos',
	'2790': 'Fabricación de otro material y equipo eléctrico',
	'2811':
		'Fabricación de motores y turbinas, excepto los destinados a aeronaves, vehículos automóviles y ciclomotores',
	'2812': 'Fabricación de equipos de transmisión hidráulica y neumática',
	'2813': 'Fabricación de otras bombas y compresores',
	'2814': 'Fabricación de otra grifería y válvulas',
	'2815': 'Fabricación de cojinetes, engranajes y órganos mecánicos de transmisión',
	'2821': 'Fabricación de hornos y quemadores',
	'2822': 'Fabricación de maquinaria de elevación y manipulación',
	'2823': 'Fabricación de máquinas y equipos de oficina, excepto equipos informáticos',
	'2824': 'Fabricación de herramientas eléctricas manuales',
	'2825': 'Fabricación de maquinaria de ventilación y refrigeración no doméstica',
	'2829': 'Fabricación de otra maquinaria de uso general n.c.o.p.',
	'2830': 'Fabricación de maquinaria agraria y forestal',
	'2841': 'Fabricación de máquinas herramienta para trabajar el metal',
	'2849': 'Fabricación de otras máquinas herramienta',
	'2891': 'Fabricación de maquinaria para la industria metalúrgica',
	'2892': 'Fabricación de maquinaria para las industrias extractivas y de la construcción',
	'2893': 'Fabricación de maquinaria para la industria de la alimentación, bebidas y tabaco',
	'2894': 'Fabricación de maquinaria para las industrias textil, de la confección y del cuero',
	'2895': 'Fabricación de maquinaria para la industria del papel y del cartón',
	'2896': 'Fabricación de maquinaria para la industria del plástico y el caucho',
	'2899': 'Fabricación de otra maquinaria para usos específicos n.c.o.p.',
	'2910': 'Fabricación de vehículos de motor',
	'2920':
		'Fabricación de carrocerías para vehículos de motor; fabricación de remolques y semirremolques',
	'2931': 'Fabricación de equipos eléctricos y electrónicos para vehículos de motor',
	'2932': 'Fabricación de otros componentes, piezas y accesorios para vehículos de motor',
	'3011': 'Construcción de barcos y estructuras flotantes',
	'3012': 'Construcción de embarcaciones de recreo y deporte',
	'3020': 'Fabricación de locomotoras y material ferroviario',
	'3030': 'Construcción aeronáutica y espacial y su maquinaria',
	'3040': 'Fabricación de vehículos militares de combate',
	'3091': 'Fabricación de motocicletas',
	'3092': 'Fabricación de bicicletas y de vehículos para personas con discapacidad',
	'3099': 'Fabricación de otro material de transporte n.c.o.p.',
	'3101': 'Fabricación de muebles de oficina y de establecimientos comerciales',
	'3102': 'Fabricación de muebles de cocina',
	'3103': 'Fabricación de colchones',
	'3109': 'Fabricación de otros muebles',
	'3211': 'Fabricación de monedas',
	'3212': 'Fabricación de artículos de joyería y artículos similares',
	'3213': 'Fabricación de artículos de bisutería y artículos similares',
	'3220': 'Fabricación de instrumentos musicales',
	'3230': 'Fabricación de artículos de deporte',
	'3240': 'Fabricación de juegos y juguetes',
	'3250': 'Fabricación de instrumentos y suministros médicos y odontológicos',
	'3291': 'Fabricación de escobas, brochas y cepillos',
	'3299': 'Otras industrias manufactureras n.c.o.p.',
	'3311': 'Reparación de productos metálicos',
	'3312': 'Reparación de maquinaria',
	'3313': 'Reparación de equipos electrónicos y ópticos',
	'3314': 'Reparación de equipos eléctricos',
	'3315': 'Reparación y mantenimiento naval',
	'3316': 'Reparación y mantenimiento aeronáutico y espacial',
	'3317': 'Reparación y mantenimiento de otro material de transporte',
	'3319': 'Reparación de otros equipos',
	'3320': 'Instalación de máquinas y equipos industriales',
	'6810': 'Compraventa de bienes inmobiliarios por cuenta propia',
	'6820': 'Alquiler de bienes inmobiliarios por cuenta propia',
	'6831': 'Agentes de la propiedad inmobiliaria',
	'6832': 'Gestión y administración de la propiedad inmobiliaria',
	'7711': 'Alquiler de automóviles y vehículos de motor ligeros',
	'7712': 'Alquiler de camiones',
	'7721': 'Alquiler de artículos de ocio y deportivos',
	'7722': 'Alquiler de cintas de vídeo y discos',
	'7729': 'Alquiler de otros efectos personales y artículos de uso doméstico',
	'7731': 'Alquiler de maquinaria y equipo de uso agrícola',
	'7732': 'Alquiler de maquinaria y equipo para la construcción e ingeniería civil',
	'7733': 'Alquiler de maquinaria y equipo de oficina, incluidos ordenadores',
	'7734': 'Alquiler de medios de navegación',
	'7735': 'Alquiler de medios de transporte aéreo',
	'7739': 'Alquiler de otra maquinaria, equipos y bienes tangibles n.c.o.p.',
	'7740':
		'Arrendamiento de la propiedad intelectual y productos similares, excepto trabajos protegidos por los derechos de autor',
	'7810': 'Actividades de las agencias de colocación',
	'7820': 'Actividades de las empresas de trabajo temporal',
	'7830': 'Otra provisión de recursos humanos',
	'7911': 'Actividades de las agencias de viajes',
	'7912': 'Actividades de los operadores turísticos',
	'7990': 'Otros servicios de reservas y actividades relacionadas con los mismos',
	'8010': 'Actividades de seguridad privada',
	'8020': 'Servicios de sistemas de seguridad',
	'8030': 'Actividades de investigación',
	'8110': 'Servicios integrales a edificios e instalaciones',
	'8121': 'Limpieza general de edificios',
	'8122': 'Otras actividades de limpieza industrial y de edificios',
	'8129': 'Otras actividades de limpieza',
	'8130': 'Actividades de jardinería',
	'8211': 'Servicios administrativos combinados',
	'8219':
		'Actividades de fotocopiado, preparación de documentos y otras actividades especializadas de oficina',
	'8220': 'Actividades de los centros de llamadas',
	'8230': 'Organización de convenciones y ferias de muestras',
	'8291': 'Actividades de las agencias de cobros y de información comercial',
	'8292': 'Actividades de envasado y empaquetado',
	'8299': 'Otras actividades de apoyo a las empresas n.c.o.p.',
	'8510': 'Educación preprimaria',
	'8520': 'Educación primaria',
	'8531': 'Educación secundaria general',
	'8532': 'Educación secundaria técnica y profesional',
	'8541': 'Educación postsecundaria no terciaria',
	'8543': 'Educación universitaria',
	'8544': 'Educación terciaria no universitaria',
	'8551': 'Educación deportiva y recreativa',
	'8552': 'Educación cultural',
	'8553': 'Actividades de las escuelas de conducción y pilotaje',
	'8559': 'Otra educación n.c.o.p.',
	'8560': 'Actividades auxiliares a la educación',
	'8610': 'Actividades hospitalarias',
	'8621': 'Actividades de medicina general',
	'8622': 'Actividades de medicina especializada',
	'8623': 'Actividades odontológicas',
	'8690': 'Otras actividades sanitarias',
	'8710': 'Asistencia en establecimientos residenciales con cuidados sanitarios',
	'8720':
		'Asistencia en establecimientos residenciales para personas con discapacidad intelectual, enfermedad mental y drogodependencia',
	'8731': 'Asistencia en establecimientos residenciales para personas mayores',
	'8732': 'Asistencia en establecimientos residenciales para personas con discapacidad física',
	'8790': 'Otras actividades de asistencia en establecimientos residenciales',
	'8811': 'Actividades de servicios sociales sin alojamiento para personas mayores',
	'8812': 'Actividades de servicios sociales sin alojamiento para personas con discapacidad',
	'8891': 'Actividades de cuidado diurno de niños',
	'8899': 'Otros actividades de servicios sociales sin alojamiento n.c.o.p.',
	'9700': 'Actividades de los hogares como empleadores de personal doméstico',
	'9810': 'Actividades de los hogares como productores de bienes para uso propio',
	'9820': 'Actividades de los hogares como productores de servicios para uso propio',
	'9900': 'Actividades de organizaciones y organismos extraterritoriales',
};
